




































import { Component, Vue } from "vue-property-decorator";
import { KinesisContainer, KinesisElement } from "vue-kinesis";

@Component({
  name: "WhatWeDo",
  components: { KinesisContainer, KinesisElement },
  props: {
    dataToShow: {}
  }
})
export default class WhatWeDo extends Vue {
  parallax = {
    type: "translate",
    duration: 1000,
    baseStrength: 50,
    perspective: 2500
  };
}
