




















import { Component, Vue } from "vue-property-decorator";
@Component({
  name: "Clients"
})
export default class Clients extends Vue {}
