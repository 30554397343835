























import { Component, Vue } from "vue-property-decorator";
import WhatWeDo from "@/components/About/About.WhatWeDo.vue";
import Clients from "@/components/About/About.Clients.vue";

@Component({
  name: "About",
  components: { Clients, WhatWeDo }
})
export default class About extends Vue {}
